import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import jQuery from 'jquery';
import 'popper.js';
import 'bootstrap';
import './assets/scss/app.scss';
import VueCarousel from 'vue-carousel';
import StarRating from 'vue-star-rating'

Vue.use(VueCarousel);

Vue.component('star-rating', StarRating);

Vue.use({
  install: function(Vue){
    Vue.prototype.$ = jQuery;
  }
});

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");