<template>
  <div class="home">
    <div class="main-container" :style="{ backgroundImage: `url(${BgImage1})` }" style="background-size: cover; background-position: center;"  >
      <div class="col-12">
        <div class="container">
          <div class="row wow  animated" style="visibility: visible;">
            <div class="text-center best-item-col" style="position: relative;">
              <div class="row">
                <div class="col-12">
                  <img src="../assets/images/nb-cover-bg2.png" class="img-fluid">
                </div>
                <div class="col-12 title-margin-top">
                  <h1 class="text-white title">
                    Wholesales &amp; Retail Supplier
                  </h1>
                  <div class="section-subtitle text-white">
                    with more than 30 years' experience
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div data-wow-delay="1s" class="container-fluid wow animated" style="visibility: visible; animation-delay: 1s; position: relative;">
      <div class="row">
        <div class="col-12 px-0">
          <img src="../assets/images/bg-top.png" width="100%" class="img-fluid">
        </div>
      </div>
    </div>
    <div class="container deal-cont" style="z-index: 1; position: relative;">
      <div class="row" data-wow-delay="0.5s">
        <div class="col-12 text-center fruit-col" style="position: relative">
          <div class="row">
            <div class="col-12 col-sm-6 my-auto">
              <div class="sub text-left wow mt-4">About Us</div>
              <h1 class="section-title text-left wow ">NB FOOD SUPPLY <a class="btn btn-primary arrow-btn" href="https://store.nb-food.com/about-us/" role="button"><i class="fa fa-angle-right" aria-hidden="true"></i></a></h1>
              <p class="text-left wow  mt-4">Ng Hock Guan started a small noodle factory and sold them from door to door to owning a company with wide range of food products, with more than 30 years of experience. Learn more about our history and journey from 1986 here.</p>
            </div>
            <div class="col-12 col-sm-6">
              <img class="img-fluid" src="../assets/images/about-bg.jpg">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container signature-cont">
      <div class="row wow " data-wow-delay="0.5s">
        <div class="col-12 text-center mb-5">
          <h1 class="section-title">Signature Product</h1>
        </div>
      </div>
      <div class="row wow ">
        <div class="col-12 news-col wow " data-wow-delay="0.5s" style="margin-bottom: 0">
          <div class="row text-center">
            <div class="col-12 col-md-4 mb-5" style="position: relative">
              <div class="img-cont" style="position: relative">
                <img class="img-fluid" src="../assets/images/products/tkeras.png">
                <div class="overlay">
                  <div class="description">NB Tauhu Keras</div>
                  <a class="link" href="https://store.nb-food.com/product/nb-tofu-keras/">Read More</a>
                </div>
              </div>
              <a href="https://store.nb-food.com/product/nb-tofu-keras/"><div class="news-title mt-3">NB Tauhu Keras</div></a>
            </div>
            <div class="col-12 col-md-4 mb-5" style="position: relative">
              <div class="img-cont" style="position: relative">
                <img class="img-fluid" src="../assets/images/products/tkeras-masin.png">
                <div class="overlay">
                  <div class="description">NB Tauhu Keras Masin</div>
                  <a class="link" href="https://store.nb-food.com/product/nb-tauhu-keras-masin/">Read More</a>
                </div>
              </div>
              <a href="https://store.nb-food.com/product/nb-tauhu-keras-masin/"><div class="news-title mt-3">NB Tauhu Keras Masin</div></a>
            </div>
            <div class="col-12 col-md-4 mb-5" style="position: relative">
              <div class="img-cont" style="position: relative">
                <img class="img-fluid" src="../assets/images/products/tgrg.png">
                <div class="overlay">
                  <div class="description">NB Tauhu Goreng</div>
                  <a class="link" href="https://store.nb-food.com/product/nb-tofu-goreng-300g/">Read More</a>
                </div>
              </div>
              <a href="https://store.nb-food.com/product/nb-tofu-goreng-300g/"><div class="news-title mt-3">NB Tauhu Goreng</div></a>
            </div>
            <div class="col-12 col-md-4 mb-5" style="position: relative">
              <div class="img-cont" style="position: relative">
                <img class="img-fluid" src="../assets/images/products/cendol_1.5kg.png">
                <div class="overlay">
                  <div class="description">NB Cendol 1.5kg</div>
                  <a class="link" href="https://store.nb-food.com/product/nb-cendol-1-5kg/">Read More</a>
                </div>
              </div>
              <a href="https://store.nb-food.com/product/nb-cendol-1-5kg/">
                <div class="news-title mt-3">NB Cendol 1.5kg</div>
              </a>
            </div>
            <div class="col-12 col-md-4 mb-5" style="position: relative">
              <div class="img-cont" style="position: relative">
                <img class="img-fluid" src="../assets/images/products/cendol_500g.png">
                <div class="overlay">
                  <div class="description">NB Cendol 500g</div>
                  <a class="link" href="https://store.nb-food.com/product/nb-cendol-500g/">Read More</a>
                </div>
              </div>
              <a href="https://store.nb-food.com/product/nb-cendol-500g/"><div class="news-title mt-3">NB Cendol 500g</div></a>
            </div>
            <div class="col-12 col-md-4 mb-5" style="position: relative">
              <div class="img-cont" style="position: relative">
                <img class="img-fluid" src="../assets/images/products/santan_kelapa.png">
                <div class="overlay">
                  <div class="description">NB Santan Kelapa</div>
                  <a class="link" href="https://store.nb-food.com/product/nb-santan-kelapa-250g/">Read More</a>
                </div>
              </div>
              <a href="https://store.nb-food.com/product/nb-santan-kelapa-250g/"><div class="news-title mt-3">NB Santan Kelapa</div></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid best-cont" :style="{ backgroundImage: `url(${BgImage2})` }" style="background-size: cover; background-position: top;">
      <div class="row">
        <div class="col-12">
          <div class="container">
            <div class="row wow" data-wow-delay="0.5s">
              <div class="col-12 text-center second-section-title-col">
                <h1 class="section-title quality-section-title col-7 text-white mx-auto">High Quality Products for Your Business</h1>
              </div>
            </div>
            <div class="row wow">
              <div class="col-12 text-center best-item-col" style="position: relative;">
                <!-- Mobile View -->
                <div class="d-md-none">
                  <div class="idea-product-section">
                    <div>
                      <img src="../assets/images/aa.png" class="img-fluid">
                    </div>
                  </div>
                  <div class="col-12">
                    <a href="https://store.nb-food.com/contact-us/" class="btn btn-primary enquiry-btn">Enquiry <i class="fa fa-angle-right" aria-hidden="true"></i></a>
                  </div>
                </div>

                <!-- Desktop View -->
                <div class="d-none d-md-block">
                  <div class="row align-items-center justify-content-center">
                    <div class="col-12 idea-product-section">
                      <div>
                        <img src="../assets/images/aa.png" class="img-fluid" style="height: 100vh;">
                      </div>
                    </div>
                    <div class="col-12">
                      <a href="https://store.nb-food.com/contact-us/" class="btn btn-primary enquiry-btn">Enquiry <i class="fa fa-angle-right" aria-hidden="true"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-12 text-center mb-5 mb-md-5 wow ">
              <h1 class="section-title">Our Product Guarantee</h1>
              <div class="section-subtitle">Flavor Taste of Perfect Cuisines</div>
            </div>
            <div class="col-12 special-timeline-cont wow slideInLeft" data-wow-delay="0.5s" >
              <img class="img-fluid" src="../assets/images/special_v2.png">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid" :style="{ backgroundImage: `url(${BgImage3})` }" style="background-size: cover; background-position: top;">
      <div class="row">
        <div class="col-12">
          <!-- <div class="row wow bounceIn">
            <div class="col-12 member-col">
              <carousel :per-page="1" :center-mode="true" :per-page-custom="[[100, 1], [768, 1], [1025, 1] ]" :pagination-enabled="true" :scroll-per-page="true" :navigation-enabled="false" :pagination-active-color="'#FFF'">
                <slide>
                  <div class="row text-center">
                    <div class="col-12">
                      <img class="img-fluid member-img mb-4" src="../assets/images/member1.png">
                    </div>
                    <div class="col-12">
                      <h4 class="member-name text-white">Sam Smith</h4>
                      <div class="member-desc text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ultricies ultrices nisl at efficitur. Nullam et ipsum justo. Cras rutrum ante nec libero finibus molestie. Donec sed lobortis metus. Proin eu pretium metus, ac viverra metus.</div>
                    </div>
                  </div>
                </slide>
                <slide>
                  <div class="row text-center">
                    <div class="col-12">
                      <img class="img-fluid member-img mb-4" src="../assets/images/member2.png">
                    </div>
                    <div class="col-12">
                      <h4 class="member-name text-white">Sam Smith</h4>
                      <div class="member-desc text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ultricies ultrices nisl at efficitur. Nullam et ipsum justo. Cras rutrum ante nec libero finibus molestie. Donec sed lobortis metus. Proin eu pretium metus, ac viverra metus.</div>
                    </div>
                  </div>
                </slide>
                <slide>
                  <div class="row text-center">
                    <div class="col-12">
                      <img class="img-fluid member-img mb-4" src="../assets/images/member3.png">
                    </div>
                    <div class="col-12">
                      <h4 class="member-name text-white">Sam Smith</h4>
                      <div class="member-desc text-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ultricies ultrices nisl at efficitur. Nullam et ipsum justo. Cras rutrum ante nec libero finibus molestie. Donec sed lobortis metus. Proin eu pretium metus, ac viverra metus.</div>
                    </div>
                  </div>
                </slide>
              </carousel>
            </div>
          </div> -->
          <div class="container logo-container">
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-6 col-md-4 wow bounceIn logo-col" data-wow-delay="0.2s">
                    <img class="img-fluid" src="../assets/images/brand/win_logo.png">
                  </div>
                  <div class="col-6 col-md-4 wow bounceIn logo-col" data-wow-delay="0.6s">
                    <img class="img-fluid" src="../assets/images/brand/laksamana_logo.jpg">
                  </div>
                  <div class="col-6 col-md-4 wow bounceIn logo-col" data-wow-delay="0.6s">
                    <img class="img-fluid" src="../assets/images/brand/yili-logo.jpg">
                  </div>
                  <div class="col-6 col-md-4 wow bounceIn logo-col" data-wow-delay="0.6s">
                    <img class="img-fluid" src="../assets/images/brand/ulumas-logo.jpg">
                  </div>
                  <div class="col-6 col-md-4 wow bounceIn logo-col" data-wow-delay="0.4s">
                    <img class="img-fluid" src="../assets/images/brand/mastika_logo.png">
                  </div>
                  <div class="col-6 col-md-4 wow bounceIn logo-col" data-wow-delay="0.6s">
                    <img class="img-fluid" src="../assets/images/brand/lo_sam_logo.jpg">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid best-cont bottom-bg" :style="{ backgroundImage: `url(${BgImage5})` }" style="background-size: cover; background-position: center center; display: flex; align-items: center; justify-content: center;">
      <div class="row">
        <div class="col-12" style="margin-top: 7rem;">
          <div class="container">
            <div data-wow-delay="0.5s" class="row wow animated animated" style="visibility: visible; animation-delay: 0.5s;">
              <div class="col-12 text-center mb-5">
                <h1 class="section-title"> Any Ideas or Products? <br>Contact Us to Work Together! </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid bottom-base" :style="{ backgroundImage: `url(${BgImage4})` }" style="background-size: cover; background-position: top;">
      <div class="row">
        <div class="col-12">
          <div class="container special-cont mb-5">
            <div class="row text-center text-white">
              <div class="col-6 col-md-3">
                <img class="img-fluid" src="../assets/images/logo/halal-cert-logo.png">
              </div>
              <div class="col-6 col-md-3">
                <img class="img-fluid" src="../assets/images/logo/mesti-logo.png">
              </div>
              <div class="col-6 col-md-3">
                <img class="img-fluid" src="../assets/images/logo/haccp-logo.png">
              </div>
              <div class="col-6 col-md-3">
                <img class="img-fluid" src="../assets/images/logo/gmp-logo.png">
              </div>
            </div>
            <!--<div class="row text-center text-white">
              <div class="col-6 col-md-3">
                <i class="fa fa-truck special-icon" aria-hidden="true"></i>
                <div class="title">Free Shipping<br>on order over RM 99</div>
              </div>
              <div class="col-6 col-md-3">
                <i class="fa fa-phone special-icon" aria-hidden="true"></i>
                <div class="title">Have Questions?<br>016-201 7875</div>
              </div>
              <div class="col-6 col-md-3">
                <i class="fa fa-usd special-icon" aria-hidden="true"></i>
                <div class="title">100% Money Back<br>Guarantee</div>
              </div>
              <div class="col-6 col-md-3">
                <i class="fa fa-briefcase special-icon" aria-hidden="true"></i>
                <div class="title">30 Days<br>Return Service</div>
              </div>
            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import BgImage1 from '../assets/images/nb-cover-bg1.png';
import BgImage2 from '../assets/images/bg-bottom.png';
import BgImage3 from '../assets/images/bg3.png';
import BgImage4 from '../assets/images/bg4.png';
import BgImage5 from '../assets/images/bg-bottom2.jpg';
import { WOW } from 'wowjs';

export default {
  name: "Home",
  components: {
    //HelloWorld
  },
  data () {
    return {
      BgImage1,
      BgImage2,
      BgImage3,
      BgImage4,
      BgImage5
    }
  },
  mounted() {
    const wow = new WOW();

    wow.init();
  }
};
</script>
